import React, { useEffect, useReducer, useState } from "react";
import { Modal, ModalBody, ModalClose, ViewportCenteredFixed } from "../modals/Modal";
import { StyledOverlayBackdrop } from "../modals/StyledOverlayBackdrop";
import { TriageModal, TriageModalBody } from "../modals/TriageModal";
import { postUserInterestLog, track } from "../analytics/track";
import { EVENTS } from "@upsolve/shared";
import { $TSFixMe } from "@upsolve/shared/dist/types";
import { flattenDeep } from "lodash";
import { initialState, reducer, TriageContext } from "./productSelectorModalComponents/state";
import {
  DebtSpecialistDisqualifyScreen,
  HasIncomeScreen,
  DebtSpecialistOfferingScreen,
  ConsumerDebtOverviewScreen,
  TrustedLawyerScreen,
  DebtQuestionScreen,
  NonConsumerDebtScreen,
  DebtSpecialistNotInterestedScreen,
} from "./productSelectorModalComponents/Screens";

type OnHideFunction = (...args: any[]) => void;

interface ProductSelectorModalProps {
  onHide: OnHideFunction;
  trackingPageContext: $TSFixMe;
}

export const ProductSelectorModal: React.FC<ProductSelectorModalProps> = ({ onHide, trackingPageContext }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const debtQuestionScreen = { name: "debtQuestionScreen", component: <DebtQuestionScreen /> };
  const nonConsumerDebtScreen = { name: "nonConsumerDebtScreen", component: <NonConsumerDebtScreen /> };
  const consumerDebtOverviewScreen = { name: "consumerDebtOverviewScreen", component: <ConsumerDebtOverviewScreen /> };
  const trustedLawyerScreen = { name: "trustedLawyerScreen", component: <TrustedLawyerScreen /> };
  const hasIncomeScreen = { name: "hasIncomeScreen", component: <HasIncomeScreen /> };
  const debtSpecialistDQScreen = { name: "debtSpecialistDQScreen", component: <DebtSpecialistDisqualifyScreen /> };
  const debtSpecialistOfferingScreen = {
    name: "debtSpecialistOfferingScreen",
    component: <DebtSpecialistOfferingScreen />,
  };
  const debtSpecialistNotInterestedScreen = {
    name: "debtSpecialistNotInterestedScreen",
    component: <DebtSpecialistNotInterestedScreen />,
  };

  const debtSpecialistScreens = [
    hasIncomeScreen,
    !state.hasIncome && debtSpecialistDQScreen,
    debtSpecialistOfferingScreen,
    debtSpecialistNotInterestedScreen,
  ].filter((screen) => screen !== false) as { name: string; component: JSX.Element }[];

  const consumerDebtScreens = [
    consumerDebtOverviewScreen,
    state.product === "talkToLawyer" ? trustedLawyerScreen : debtSpecialistScreens,
  ];

  const flow = [debtQuestionScreen, state.debtType === "consumerDebt" ? consumerDebtScreens : nonConsumerDebtScreen];

  const [stepIndex, setStepIndex] = useState(0);

  const onNext = () => {
    setStepIndex((currentStep) => currentStep + 1);
  };

  const flattenedFlow = flattenDeep(flow);
  const step = flattenedFlow[stepIndex];

  useEffect(() => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtTriage",
      componentVersion: "1",
      actionId: `opened`,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
  }, []);

  const trackUserInterest = async () => {
    await postUserInterestLog({ productInterest: state.product, debtType: state.debtType });
  };

  const onDone = () => {
    trackUserInterest();
    // onHide();
  };

  const trackClick = (actionId: string) => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtTriage",
      componentVersion: "1",
      actionId,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
  };

  const handleClose = () => {
    track(EVENTS.COMPONENT_ACTION, {
      componentName: "debtTriage",
      componentVersion: "1",
      actionId: `closed.${step.name}`,
      actionMethod: "click",
      pageContext: trackingPageContext,
    });
    trackUserInterest();
    onHide();
  };

  const StepManager = () => {
    return step.component || <></>;
  };

  return (
    <>
      <ViewportCenteredFixed>
        <TriageModal>
          <ModalClose onClick={handleClose} />
          <TriageModalBody>
            <TriageContext.Provider value={{ dispatch, state, onDone, onNext, trackClick }}>
              <StepManager />
            </TriageContext.Provider>
          </TriageModalBody>
        </TriageModal>
      </ViewportCenteredFixed>
      <StyledOverlayBackdrop onClick={handleClose} />
    </>
  );
};
