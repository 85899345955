import axios from "axios";
import Bowser from "bowser";
import { get } from "lodash";
import { EVENTS } from "@upsolve/shared";
import { TTrackingEvent } from "@upsolve/shared/dist/types";
import { v4 as uuidV4 } from "uuid";
import { addYears, format } from "date-fns";

const universalAnonymousIdCookieName = "upsolveIdentifier";
const deprecatedDeviceIdLocalStorageKey = "upsolveTrackingDeviceId";
const cookieDomain = process.env.NODE_ENV !== "local" ? ".upsolve.org" : "localhost";

//make sure this code is in sync with anywhere we want cross-site tracking, e.g. filer
//TODO: move this into shared package once it's stabilized
const setCookie = (name: string, value: string) => {
  //looks like chrome only allows you to set an expiration/max age 400 days into the future, so this gets truncated
  const twoYearsFromNow = addYears(new Date(), 2);
  const cookieParameters = {
    [name]: value,
    domain: cookieDomain, //available on all domains and subdomains, e.g. my.upsolve.org AND upsolve.org
    expires: format(twoYearsFromNow, "EEE, dd MMM yyyy HH:mm:ss OOO"),
    path: "/",
  };
  document.cookie = Object.entries(cookieParameters)
    .map(([key, value]) => `${key}=${value}`)
    .join("; ");
};

const refreshCookie = (name: string) => {
  const oldCookieValue = getCookie(name);
  if (oldCookieValue) {
    setCookie(name, oldCookieValue);
  }
};

function getCookie(name: string) {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

// The goal here is to have a way to track events by "anonymous" users that
// haven't signed up with an account, and to do it across our subdomains
// (upsolve.org and my.upsolve.org primarily). We do this by setting the
// anonymous ID to a cookie that will persist across our subdomains. A few
// notes:
//1. We call amplitude on the backend, and they have their own way to track
//   "anonymous users". However, using amplitude's device id errored 90% of
//   times in practice, but for web apps they seem to use a uuid approach
//   anyways
//2. Even with amplitude's approach, they still require you to get the same
//   identifier across your different web apps somehow
//   https://help.amplitude.com/hc/en-us/articles/115003135607-Track-unique-users
const findOrCreateAnonymousId = () => {
  const currentAnonymousId = getCookie(universalAnonymousIdCookieName);
  if (currentAnonymousId) {
    refreshCookie(universalAnonymousIdCookieName);
    return currentAnonymousId;
  }

  //TODO: deprecate this 6 months from this comment
  const deprecatedDeviceId = localStorage.getItem(deprecatedDeviceIdLocalStorageKey);
  if (deprecatedDeviceId) {
    return deprecatedDeviceId;
  }

  const anonymousId = uuidV4();
  setCookie(universalAnonymousIdCookieName, anonymousId);
  return anonymousId;
};

export enum CTATrackingTarget {
  SCREENER = "CTA.myUpsolveNavigation",
  AJM = "CTA.AJM",
  LOGIN = "CTA.LOGIN",
  STUDENT_LOANS = "CTA.STUDENT_LOANS",
  DEBT_TRIAGE = "CTA.DEBT_TRIAGE",
}
// 'private' track call so we can conditionally chose to wrap in setTimeout below
const _track = async (event: string, fields: TTrackingEvent["fields"]) => {
  try {
    const browserProps = Bowser.parse(window.navigator.userAgent);

    const anonymousId = findOrCreateAnonymousId();

    // Build props
    const trackBody: TTrackingEvent = {
      type: event,
      fields: {
        deviceId: anonymousId,
        browser: {
          name: browserProps?.browser.name ?? "",
          version: browserProps?.browser.version ?? "",
        },
        canonicalUrl: fields.canonicalUrl ?? get(document.querySelector('link[rel="canonical"]'), "href"),
        componentName: fields.componentName,
        componentVersion: fields.componentVersion,
        actionId: fields.actionId,
        actionMethod: fields.actionMethod,
        os: {
          name: browserProps?.os?.name ?? "",
          versionName: browserProps?.os?.versionName ?? "",
          version: browserProps?.os?.version ?? "",
        },
        pageContext: {
          contentCreationMethod: fields.pageContext?.contentCreationMethod,
          contentGroup: fields.pageContext?.contentGroup,
          localeSpecific: fields.pageContext?.localeSpecific,
          jsonldTypes:
            typeof document !== "undefined"
              ? Array.from(document.querySelectorAll("script[type='application/ld+json']")).map(
                  (dom) => JSON.parse(dom.innerHTML)["@type"]
                )
              : undefined,
          pageComponentName: fields.pageContext?.pageComponentName,
          pageComponentVersion: fields.pageContext?.pageComponentVersion,
          publishedAt: fields.pageContext?.publishedAt,
          searchTermTargets: Array.from(fields.pageContext?.searchTermTargets ?? []).filter((stt) => stt),
          slug: fields.pageContext?.slug,
          tags: fields.pageContext?.tags,
          title: fields.pageContext?.title ?? (typeof document !== "undefined" ? document.title : undefined),
          updatedAt: fields.pageContext?.updatedAt,
          wordCount: fields.pageContext?.wordCount,
        },
        path: window.location.pathname,
        platform: {
          type: browserProps?.platform?.type ?? "",
          vendor: browserProps?.platform?.vendor ?? "",
        },
        referrer: document.referrer,
        screenHeight: window.innerHeight,
        screenWidth: window.innerWidth,
        url: window.location.href,
      },
    };

    //track via our backend to get around ad-block issues, as well as to keep a
    //copy of events in our database to prevent vendor lock-in
    await axios.post(`${UPSOLVE_API_URL}/v1/tracking`, trackBody);
  } catch (error) {
    console.warn(error);
  }
};
type UserInterestLogProps = {
  email?: string;
  productInterest?: string;
  debtType?: string;
};
// TODO: possibly return ID so we can update the log as user goes thru the prompts
export const postUserInterestLog = async (userInterestLog: UserInterestLogProps) => {
  try {
    await axios.post(`${UPSOLVE_API_URL}/v1/userInterestLog`, userInterestLog);
  } catch (e) {
    // TODO use real error handler
    console.error(e);
  }
};

// HACK: Doing a setTimeout to let react components render, specifically react helmet which may modify the page title/jsonld
export const track = async (event: string, fields: TTrackingEvent["fields"]) =>
  event === EVENTS.PAGE_VIEW ? setTimeout(() => _track(event, fields)) : _track(event, fields);
