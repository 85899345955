import React, { Fragment, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { theme } from "@upsolve/ui";

import { queryClient } from '../queries/queryClient';
import config from "../../../site-config";
import GlobalStyle from "../GlobalStyle";

const TopLevelLayout = (props) => {
  useEffect(() => console.log(upsolveConsoleLog), []);
  return (
    <Fragment>
      <GlobalStyle />
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
      </QueryClientProvider>
    </Fragment>
  );
};

const upsolveConsoleLog = `
██████████████████████████████████████████████████████████

██╗   ██╗██████╗ ███████╗ ██████╗ ██╗    ██╗   ██╗███████╗
██║   ██║██╔══██╗██╔════╝██╔═══██╗██║    ██║   ██║██╔════╝
██║   ██║██████╔╝███████╗██║   ██║██║    ██║   ██║█████╗
██║   ██║██╔═══╝ ╚════██║██║   ██║██║    ╚██╗ ██╔╝██╔══╝
╚██████╔╝██║     ███████║╚██████╔╝███████╗╚████╔╝ ███████╗
 ╚═════╝ ╚═╝     ╚══════╝ ╚═════╝ ╚══════╝ ╚═══╝  ╚══════╝

██████████████████████████████████████████████████████████

👋 Yo!
👩‍💻 Would you like to:

⚬ Improve access to an under-utilized social safety net?
⚬ Create a countering force to predatory lenders?
⚬ Change the perception of what a nonprofit can do?
⚬ Get in a good kind of a trouble?

📬 Email: dev@upsolve.org

██████████████████████████████████████████████████████████
`;

export default TopLevelLayout;
